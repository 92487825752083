<div class="box">
    <p class="title is-4">Nombre plato</p>
    <p class="m-b-sm">Largo listado de ingredientes del plato que se puede expandir</p>
    <div class="columns m-t-sm">
        <div class="column is-3 is-offset-9">
            <a class="level-item" aria-label="edit">
                <span class="icon has-text-dark">
                    <fa-icon [icon]="faEdit" size="lg"></fa-icon>
                </span>
            </a>
        </div>
    </div>
</div>