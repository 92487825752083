<ng-container *transloco="let t">
    <div class="columns is-vcentered">
        <div class="column is-3">
            <label class="label">{{ dish.originalName }}</label>
        </div>
        <div class="column is-narrow" (click)="onTranslateClick('name')">
            <a class="button">
                <fa-icon [icon]="faLanguage" size="2x"></fa-icon>
            </a>
        </div>
        <div class="column">
            <input class="input is-fullwidth" type="text" [(ngModel)]="dish.name" [class]="nameClass" (blur)="onValueChange('name')" [disabled]="isLoading">
        </div>
    </div>
    <div class="columns is-vcentered" *ngIf="dish.originalDescription.length > 0">
        <div class="column is-3">
            <label class="label">{{ dish.originalDescription }} </label>
        </div>
        <div class="column is-narrow" (click)="onTranslateClick('description')">
            <a class="button">
                <fa-icon [icon]="faLanguage" size="2x"></fa-icon>
            </a>
        </div>
        <div class="column">
            <textarea class="textarea" type="text" rows="4" [placeholder]="t('dish.description')" [class]="descriptionClass" [(ngModel)]="dish.description" (blur)="onValueChange('description')" [disabled]="isLoading"></textarea>
        </div>
    </div>
</ng-container>