<ng-container *ngIf="!isPdfMenu()" >
    <div class="bg-image" [class]="'is-style-' + menu.style">
        <div class="tab" [class]="'is-style-' + menu.style" *ngFor="let section of menu.sections">
            <input type="checkbox" id="{{ section.id }}" checked>
            
            <label class="tab-label section" for="{{ section.id }}">{{ section.name }}</label>
            <div class="tab-content" [class]="'is-style-' + menu.style">
                <table class="table is-fullwidth" [class]="'is-style-' + menu.style">
                    <tbody>
                        <tr app-client-menu-row *ngFor="let dish of section.dishes" [newDish]="dish"
                        [userAllergens]="currentUserAllergens" [hideDishes]="hideDishes"
                        [noAllergens]="menu.allergenUndeclared" [style]="menu.style"></tr>
                    </tbody>
                </table>
                <div [class]="'separator is-style-' + menu.style">
                    <img src="\assets\preview\LoNauticDecorator.jpeg"/>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="isPdfMenu()">
    <p *ngIf="pdfError.length != 0" class="has-text-centered m-t-xl">
        {{ pdfError }}</p>
    <pdf-viewer *ngIf="pdfError.length == 0" [src]='getPdf()' [fit-to-page]="true">
    </pdf-viewer>
</ng-container>