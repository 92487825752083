<ng-container *transloco="let t">

    <div class="field has-addons center">
        <div class="control">
            <button class="button is-medium" [class]=" monthlyPlan  ? 'is-secondary is-secondary-hover' : 'is-outlined'"
                (click)="onTimePlanClick(true)">Mensual</button>
        </div>
        <div class="control">
            <button class="button is-medium" [class]=" !monthlyPlan ? 'is-secondary is-secondary-hover' : 'is-outlined'"
                (click)="onTimePlanClick(false)">Anual</button>
        </div>

    </div>

    <div class="pricing-table">
        <div class="pricing-plan">
            <div class="plan-header">{{ t('pricing.basic_title') }}</div>
            <div class="plan-price"><span class="plan-price-amount">{{ getPlanPrice(basicPlan) }}<span
                        class="plan-price-currency">€</span></span>/{{ monthlyPlan ?  t('general.month') : t('general.year') }}
            </div>
            <div class="plan-items">
                <div class="plan-item">{{ t('pricing.basic_pdf_1') }}</div>
                <div class="plan-item">{{ t('pricing.basic_pdf_2') }}</div>
                <div class="plan-item">{{ t('pricing.basic_pdf_3') }}</div>
            </div>
            <div class="plan-footer">
                <button class="button is-fullwidth" [disabled]="currentPlan == basicPlan || selectedPlan == basicPlan" (click)="onPlanClick(basicPlan)">
                    {{ getButtonState(basicPlan) }}
                </button>
            </div>
        </div>
        <div class="pricing-plan">
            <div class="plan-header">{{ t('pricing.advanced_title') }}</div>
            <div class="plan-price"><span class="plan-price-amount">{{ getPlanPrice(advancedPlan) }}<span
                        class="plan-price-currency">€</span></span>/{{ monthlyPlan ?  t('general.month') : t('general.year') }}
            </div>
            <div class="plan-items">
                <div class="plan-item">{{ t('pricing.basic_i1') }}</div>
                <div class="plan-item">{{ t('pricing.basic_i2') }}</div>
                <div class="plan-item">{{ t('pricing.advanced_i2') }}</div>

            </div>
            <div class="plan-footer">
                <button class="button is-fullwidth" [disabled]="currentPlan == advancedPlan || selectedPlan == advancedPlan" (click)="onPlanClick(advancedPlan)">
                    {{ getButtonState(advancedPlan) }}
                </button>
            </div>
        </div>

        <div class="pricing-plan">
            <div class="plan-header">{{ t('pricing.professional_title') }}</div>
            <div class="plan-price"><span class="plan-price-amount">{{ getPlanPrice(professionalPlan) }}<span
                        class="plan-price-currency">€</span></span>/{{ monthlyPlan ?  t('general.month') : t('general.year') }}
            </div>
            <div class="plan-items">
                <div class="plan-item">{{ t('pricing.professional_i1') }}</div>
                <div class="plan-item" [innerHTML]=" t('pricing.professional_i2')"></div>
            </div>
            <div class="plan-footer">
                <button class="button is-fullwidth" [disabled]="currentPlan == professionalPlan || selectedPlan == professionalPlan" (click)="onPlanClick(professionalPlan)">
                    {{ getButtonState(professionalPlan)  }}
                </button>
            </div>
        </div>

        <div class="pricing-plan" *ngIf="currentPlan == noPlan">
            <div class="plan-header">{{ t('pricing.custom_title') }}</div>
            <div class="plan-price"><span class="plan-price-amount">49<span
                        class="plan-price-currency">€</span></span>{{ t('pricing.unique') }}</div>
            <div class="plan-items">
                <div class="plan-item">{{ t('pricing.custom_i1') }}</div>
                <div class="plan-item" [innerHTML]=" t('pricing.custom_i2')"></div>
                <div class="plan-item">{{ t('pricing.custom_i3') }}</div>
                <div class="plan-item">{{ t('pricing.custom_i4') }}</div>
            </div>
            <div class="plan-footer">
                <ng-container> 
                    <input class="is-checkradio" id="porTi" type="checkbox" name="porTi" [checked]="porTi"
                        (change)="onPortiClick()">
                    <label for="porTi">{{ t('general.add') }}</label>
                </ng-container>
                <!--ng-container *ngIf="currentPlan != undefined">
                    <button class="button is-fullwidth" (click)="onPlanClick('porti')">
                        {{ t('pricing.choose')  }}
                    </button>
                </ng-container-->
            </div>
        </div>
    </div>
</ng-container>