import { MenuService } from './../../../services/menu.service';
import { Component, OnInit, Input } from '@angular/core';
import { faEdit, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { Menu } from 'src/app/classes/menu';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss']
})
export class MenuItemComponent implements OnInit {

  @Input() menu: Menu;
  faEdit = faEdit;
  faPdf = faFilePdf;
  constructor(private router: Router, private menuService: MenuService) { }

  ngOnInit() {
  }

  onEditClick() {
    switch (this.menu.style) {
      case 2:
        this.router.navigate(['menu/editpdf/' + this.menu.id]);
        break;
      default:
        this.router.navigate(['menu/edit/' + this.menu.id]);
        break;
    }
  }

  onActivateClick() {
    this.menu.active = !this.menu.active;
    this.menuService.activateMenu(this.menu).subscribe(() => { }, error => {
      this.menu.active = !this.menu.active;
    });
  }

}
