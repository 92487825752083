<ng-container *transloco="let t">
    <div class="box content">

        <p class="title is-4">{{ menu.name }} <fa-icon class="is-pulled-right is-gray" [icon]="faPdf" *ngIf="menu.style == 2"></fa-icon></p>

        <div class="is-flex flex-between flex-end m-t-xl">
            <button class="button" [ngClass]="menu.active ? 'is-success' : 'is-danger'" (click)="onActivateClick()">
                {{ menu.active ? t('menu.active') : t('menu.inactive')}}
            </button>

            <button class="button" (click)="onEditClick()">{{ t('general.edit') }}</button>
        </div>


        <!--button class="m-t-md button is-danger" >Inactivo</!--button-->

    </div>
</ng-container>